<template>
  <div style="margin: 0 auto; max-width: 980px;" class="pa-2">
    <ButtonUI type="secondary" @click.native="() => $router.push('/auth/login')">Вход для зарегистрированных
    </ButtonUI>
    <v-form ref="form" @submit="signUp">
      <HeaderDiv>
        <template v-slot:title>
          <h2 class="py-4">Регистрация в АИС</h2>
        </template>
      </HeaderDiv>
      <InputUI :errors="errors.last_name" :is-valid="valid.last_name" tabindex="0" v-model="user.last_name"
               :error-messages="errors.last_name"
               name="given-name"
               label="Фамилия"
               :suggest-func="lastNameSuggester"
               placeholder="Иванов"></InputUI>
      <InputUI :errors="errors.first_name" tabindex="1" v-model="user.first_name"
               :error-messages="errors.first_name"
               name="family-name"
               label="Имя"
               :suggest-func="nameSuggester"
               placeholder="Имя"></InputUI>
      <InputUI :errors="errors.third_name" tabindex="2" v-model="user.third_name"
               :error-messages="errors.third_name"
               name="additional-name"
               label="Отчество"
               :suggest-func="thirdNameSuggester"
               placeholder="Отчество"></InputUI>

      <h4 class="my-3">Контактные данные</h4>
      <InputUI tabindex="3" v-model="user.email"
               :errors="errors.email"
               name="email"
               outlined placeholder="Email" type="email"/>

      <InputUI tabindex="4" v-model="user.phone"
               v-mask="'\+7 (###) ###-##-##'"
               :errors="errors.phone"
               :rules="[ v => !!v || 'Телефон обязателен']"
               name="phone"
               outlined
               placeholder="Телефон" type="phone"
               validate-on-blur/>

      <InputUI tabindex="5" v-model="user.password"
               :errors="errors.password"
               name="password" outlined placeholder="Пароль"
               type="password"/>
      <InputUI tabindex="6"
               v-model="user.password_confirm"
               name="password_confirm"
               placeholder="Подтверждение пароля" type="password"/>


      <h4 class="my-4">Выберите вашу роль</h4>
      <SelectorUI tabindex="7" v-model="user.role" :items="[
                                         {value: 'employee', name: 'Сотрудник'},
                                         {value: 'student', name: 'Студент'},
                                         {value: 'pupil', name: 'Школьник'},
                                         {value: 'company', name: 'Внешний партнер'},
                                         {value: 'visitor', name: 'Другое'},
                                      ]" default-value="student"/>


      <div v-if="user.role === 'student'" class="my-3">
        <h4>Студенческая группа</h4>
        <SelectUI tabindex="8"
                  id="student_group_id"
                  v-model="user.student_group"
                  :items="$store.state.dictionaries.studentGroups"
                  class="mt-4"
                  item-text="name"
                  item-value="id"
                  placeholder="Учебная группа"
        />

      </div>
      <div v-if="user.role === 'employee'" class="my-4">
        <h4>Информация о сотруднике</h4>

        <SelectUI tabindex="9" v-model="user.department"
                  :items="$store.state.dictionaries.departments"
                  class="mt-2"
                  item-text="name"
                  item-value="id"
                  placeholder="Структурное подразделение"
        />
        <InputUI tabindex="10" v-model="user.employee_post"
                 class="mt-4"
                 outlined
                 placeholder="Должность"
        />


      </div>
      <div v-if="user.role === 'pupil'">
        <h4 class="my-4">Информация об ученике</h4>
        <InputUI tabindex="11"
                 v-model="user.pupil_school"
                 placeholder="Учебное заведение"
        />

        <InputUI tabindex="12"
                 v-model="user.pupil_class"
                 placeholder="Класс"
        />
      </div>

      <InputUI tabindex="13" class="my-4" v-if="user.role === 'company'"
               v-model="user.company_name"
               :error-messages="errors.company_name"
               placeholder="Название организации"/>

      <v-card outlined>
        <v-card-text>
          <v-checkbox :error-messages="!user.agree_pp && ['Необходимо дать согласие']"  v-model="user.agree_pp"
                       color="green"
                       value="1"
          ></v-checkbox> Я ознакомлен с &nbsp; <a href="https://student.surgu.ru/#/materials/75"> согласием на обработку</a>
          &nbsp;персональных данных и даю согласие на обработку моих персональных данных.
        </v-card-text>
      </v-card>

      <ButtonUI :disabled="!user.agree_pp" tabindex="14" :loading="loading" class="my-4" color="primary" @click.native.prevent="signUp">
        Зарегистрироваться
      </ButtonUI>


    </v-form>
  </div>
</template>

<script>
import {mask} from 'vue-the-mask'
import InputUI from "@/components/UI/InputUI";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import HeaderDiv from "@/components/UI/HeaderDiv.vue";
import SelectorUI from "@/components/UI/SelectorUI.vue";
import SelectUI from "@/components/UI/SelectUI.vue";
import suggests from "@/api/suggests.js";

export default {
  directives: {
    mask,
  },
  components: {ButtonUI, InputUI, HeaderDiv, SelectorUI, SelectUI},
  name: "SignupComponent",
  mounted() {
    this.$store.dispatch('getStudentGroups')
    this.$store.dispatch('getDepartments')
  },
  computed: {
    canSignUp() {
      return true;
    },
    valid() {
      return {}
    }
  },
  data() {
    return {
      form_valid: true,
      loading: false,
      snackbar: false,
      user: {
        first_name: '',
        last_name: '',
        third_name: '',
        email: '',
        password: '',
        password_confirm: '',
        role: 'visitor',
        phone: '',
        agree_pp: 0,
        student_group: '',
        department: '',
        employee_post: '',
        pupil_school: '',
        pupil_class: '',
        company_name: '',
      },
      errors: {}
    }
  },
  methods: {
    lastNameSuggester(value) {
      return suggests.get({text: value, part: 'surname'}).then((r) => r.data);
    },
    nameSuggester(value) {
      return suggests.get({text: value, part: 'name'}).then((r) => r.data);
    },

    thirdNameSuggester(value) {
      return suggests.get({text: value, part: 'PATRONYMIC'}).then((r) => r.data);
    },
    signUp() {
      this.loading = true;
      this.snackbar = true
      this.$store.dispatch('signUp', this.user).then(() => {
        this.$store.dispatch('authUser', {
          username: this.user.email,
          password: this.user.password
        }).then(() => {
          this.loading = false;
          if (localStorage.getItem('return_path')) {
            this.$router.push(localStorage.getItem('return_path'))
            localStorage.removeItem('return_path')
          } else this.$router.push('/');
        })
      }).catch((error) => {
        this.loading = false;
        this.errors = error.response.data.errors;
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}
</style>
