import endpoint from "@/api/endpoint";

export default {

    get({text, part}) {
        return window.axios.get(endpoint + '/api/suggests', {params: {text, part}});
    },


}
